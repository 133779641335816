<template>
  <div class="auth-form__wrapper" :class="{ 'mt-5': isAuthCardOverFlowing }">
    <v-card
      v-resize="onResizeCard"
      ref="authCardRef"
      :width="cardWidth"
      :max-width="cardWidth"
      :class="authCardClasses"
    >
      <v-card-title v-if="showHeader" class="px-5">
        <v-img
          height="32px"
          justify="center"
          class="m-auto"
          contain
          :src="$images.maropostLogo"
          alt="Maropost logo"
        />
      </v-card-title>
      <v-card-text class="mt-6 pb-0" :class="cardClasses">
        <slot name="form" />
      </v-card-text>
    </v-card>
    <template>
      <v-footer
        v-resize="onResize"
        color="transparent"
        :fixed="!isAuthCardOverFlowing"
        :class="[{ 'position-relative': isAuthCardOverFlowing }]"
        padless
      >
        <v-row no-gutters class="links my-4" v-if="showLinks">
          <v-col cols="12" md="12" lg="12">
            <span class="white--text">&copy; Maropost Inc {{ year }} </span>
            <span class="mx-3 white--text">|</span>
            <a
              target="_blank"
              v-text="'Privacy Policy'"
              :href="$appConfig.policyLink"
              class="text-decoration-none white--text"
            />
            <span id="app-version-number" class="pl-3 white--text">{{
              packageConfig.version
            }}</span>
          </v-col>
        </v-row>
      </v-footer>
    </template>
  </div>
</template>

<script>
import packageConfig from "../../../package.json";
import { DISPLAY_BREAKPOINTS } from "@/constants/app";

/**
 * Auth form wrapper
 */
export default {
  name: "AuthFormsWrapper",

  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Displays maropost footer links
     */
    showLinks: { type: Boolean, default: false },
    /**
     * Shows maropost logo and header
     */
    showHeader: { type: Boolean, default: true },
    /**
     * Width of the wrapper card component
     */
    width: { type: [String, Number], default: "" },
    /**
     * Card content classes
     */
    cardClasses: { type: [String], default: "" },
    /**
     * Component wrapper card classes
     */
    wrapperClasses: { type: String, default: "" },
    /**
     * Does parent component has an error
     */
    hasError: { type: Boolean, default: false },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      windowSize: { x: 0, y: 0 },
      authCardSize: { width: 0 },
      packageConfig,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Current year
     */
    year() {
      return new Date().getUTCFullYear();
    },
    /**
     * Is card overflowing in window element
     */
    isAuthCardOverFlowing() {
      return this.windowSize.y - this.authCardSize.width <= 80;
    },
    /**
     * authCardClasses
     * List of Auth card classes
     */
    authCardClasses() {
      return [
        !this.wrapperClasses ? "pt-6 pb-6 px-6" : this.wrapperClasses,
        { "mt-8": this.hasCardOverflown },
      ];
    },
    /**
     * hasCardOverflown
     * Tells the auth card have already overflown
     */
    hasCardOverflown() {
      return (
        (this.isAuthCardOverFlowing && this.hasError) ||
        this.windowSize.y - this.authCardSize.width < 10
      );
    },
    cardWidth() {
      const { name } = this.$vuetify.breakpoint;

      if (name == DISPLAY_BREAKPOINTS.xs) return "350px";
      else return this.width;
    },
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    hasError() {
      this.onResizeCard();
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * onResize
     * @description Runs whenever the size of windoew is changed
     */
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    /**
     * onResizeCard
     * @description Computes the realtime height of the auth card
     */
    onResizeCard() {
      this.$nextTick(() => {
        const domEl = document.querySelector(".auth-form__wrapper .v-card");
        this.authCardSize = { width: domEl?.clientHeight };
      });
    },
  },
};
</script>

<style lang="scss">
.links {
  height: calc(100% - 100px);
}
</style>
